<template>
  <v-container class="contact-form-layout landing-container">
    <div
      class="pt-6 pb-4 px-4 bg-ui-kit-bg-primary contact-form-layout-container
            d-md-flex justify-md-space-between pa-md-12"
    >
      <div class="w-100 flex-md-grow-1">
        <h2
          class="text-h5 text-center mb-2 text-md-h3 mb-md-6 text-md-start"
          v-text="title"
        />
        <p
          class="text-body-1 text-md-landing-h6 text-ui-kit-text-secondary text-center mb-6 text-md-start mb-md-0"
          v-text="subtitle"
        />
      </div>
      <div
        class="contact-form-layout-container__form bg-ui-kit-bg-gray-0
        w-100 py-6 px-4 pt-md-8 pb-md-6 px-md-6 ml-md-10"
      >
        <v-form
          v-model="isValidForm"
          validate-on="submit"
          @submit.prevent="handleClickSend"
        >
          <v-radio-group
            v-if="!variantRequestType"
            v-model="requestType"
            class="d-none d-md-inline"
            inline
          >
            <v-radio
              v-for="(buttonRequest, index) in CONTACT_TYPES"
              :key="`radio-${index}`"
              class="contact-form-layout__radio-button"
              color="primary"
              :label="buttonRequest.label"
              :value="buttonRequest.value"
            />
          </v-radio-group>
          <v-select
            v-if="!variantRequestType"
            v-model="requestType"
            label="От кого заявка"
            item-title="label"
            item-value="value"
            density="comfortable"
            class="d-md-none mb-4"
            :items="CONTACT_TYPES"
          />

          <v-expand-transition
            leave-absolute
            hide-on-leave
          >
            <div v-show="isShowClinicsField">
              <v-text-field
                ref="townTextField"
                v-model="townName"
                label="Город"
                density="comfortable"
                validate-on="blur"
                class="pb-4"
                :rules="townNameRules"
                @click:control="townTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>
          <v-expand-transition
            leave-absolute
            hide-on-leave
          >
            <div v-show="isShowClinicsField">
              <v-text-field
                ref="clinicTextField"
                v-model="clinicTitle"
                class="pb-4"
                label="Название клиники"
                density="comfortable"
                validate-on="blur"
                :rules="clinicTitleRules"
                @click:control="clinicTextField?.resetValidation()"
              />
            </div>
          </v-expand-transition>

          <PhoneInput
            v-model="phoneNumber"
            class="mb-4"
            label="Номер телефона"
            placeholder="+7 9XX XXX XX XX"
            density="comfortable"
            validate-on="blur"
            :rules="phoneNumberRules"
            persistent-placeholder
          />

          <v-btn
            min-width="100%"
            type="submit"
            text="Заказать консультацию"
            class="mb-6"
            :loading="formLoading"
          />

          <div class="text-center text-body-2">
            Нажимая кнопку, вы&nbsp;даёте согласие на&nbsp;<a
              target="_blank"
              class="text-primary text-decoration-none"
              :href="privacyPolicyLink"
            >обработку персональных&nbsp;данных</a>
          </div>
        </v-form>
      </div>
    </div>
    <ContactFormFinalScreens
      v-model="isShowFinalScreens"
      :is-show-success-screen="isShowSuccessScreen"
    />
  </v-container>
</template>

<script setup lang="ts">
import { useContactForm } from '~/components/ContactForm/composables/useContactForm'
import { CONTACT_TYPES } from '~/components/ContactForm/constants'
import type { ContactFormRequest } from '~/enums'

type Props = {
  title?: string
  subtitle?: string
  variantRequestType?: ContactFormRequest
}

const props = withDefaults(defineProps<Props>(), {
  title: `Подключитесь ${textNoWrap('к МедФлексу')}`,
  subtitle: 'Наш менеджер свяжется с вами и расскажет подробности',
  variantRequestType: undefined
})

const {
  isShowSuccessScreen,
  isShowFinalScreens,
  privacyPolicyLink,
  isValidForm,
  townTextField,
  clinicTextField,
  handleClickSend,
  formLoading,
  phoneNumber,
  townName,
  clinicTitle,
  isShowClinicsField,
  requestType,
  townNameRules,
  clinicTitleRules,
  phoneNumberRules
} = useContactForm({
  propsRequestType: props.variantRequestType
})
</script>

<style scoped lang="scss">
@use "sass:map";

.contact-form-layout {
  margin-bottom: 60px;

  &-container {
    border-radius: 16px;

    &__form {
      border-radius: 8px;
      max-width: 450px;
    }
  }

  &__radio-button {
    margin-right: 16px;
  }

  &__radio-button:last-of-type {
    margin-right: -5px;
  }

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 100px;
  }
}
</style>
