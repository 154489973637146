<template>
  <HomeSection
    class="container-indent"
    title="Наши партнёры"
    :subtitle="subtitle"
  >
    <div class="mx-n4">
      <div
        v-if="!showOnlyPartnerType"
        class="d-flex justify-center gc-2 mb-6"
      >
        <v-chip-group
          v-model="selectedFilterId"
          mandatory
        >
          <div class="partner-section-group-stub">&nbsp;</div>
          <v-chip
            v-for="chip in PARTNERS_CHIPS"
            :key="chip.value"
            selected-class="bg-ui-kit-bg-primary border-color-primary"
          >
            {{ chip.title }}
          </v-chip>
          <div class="partner-section-group-stub">&nbsp;</div>
        </v-chip-group>
      </div>
      <div class="d-flex flex-wrap justify-center gc-4 gc-md-3">
        <v-img
          v-for="selectedImage in selectedImages"
          :key="selectedImage.title"
          class="flex-grow-0 partner-section-img"
          :src="selectedImage.logoUrl"
          :alt="selectedImage.title"
        />
      </div>
    </div>
  </HomeSection>
</template>

<script setup lang="ts">
import { ASYNC_DATA_KEYS } from '~/constants'
import {
  GOVERNMENT_LOGOS,
  LPUS_LOGOS,
  PARTNERS_CHIPS, PARTNERS_TYPES
} from '~/components/PartnerSection/constants'
import type { PartnerTypes } from '~/components/PartnerSection/types'

const { data: logos } = useNuxtData(ASYNC_DATA_KEYS.logos)

type Props = {
  showOnlyPartnerType?: PartnerTypes
  subtitle?: string
}

const props = defineProps<Props>()
const selectedFilterId = ref(0)

const selectedImages = computed(() => {
  const partnerFilter = props.showOnlyPartnerType ?? PARTNERS_CHIPS[selectedFilterId.value].value

  switch (partnerFilter) {
    case PARTNERS_TYPES.servicesAndInsurance:
      return [...logos.value.services, ...logos.value.insurance]
    case PARTNERS_TYPES.lpu:
      return LPUS_LOGOS
    case PARTNERS_TYPES.insurance:
      return logos.value?.insurance
    case PARTNERS_TYPES.services:
      return logos.value?.services
    case PARTNERS_TYPES.government:
      return GOVERNMENT_LOGOS
  }
})
const imageSizes = {
  desktop: {
    width: '214px',
    height: '88px'
  },
  mobile: {
    width: '156px',
    height: '69px'
  }
}
</script>

<style lang="scss" scoped>
@use "sass:map";

.partner-section-img {
  width: v-bind('imageSizes.mobile.width');
  height: v-bind('imageSizes.mobile.height');

  @media (min-width: $desktop-breakpoint) {
    width: v-bind('imageSizes.desktop.width');
    height: v-bind('imageSizes.desktop.height');
  }
}

.partner-section-group-stub {
  width: 16px;
}

body .border-color-primary {
  border-color: $t-primary !important;
}

.container-indent {
  margin-bottom: 60px;

  @media #{map.get($display-breakpoints, 'md-and-up')} {
    margin-bottom: 100px;
  }
}
</style>
