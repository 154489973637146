<template>
  <section class="landing-container">
    <div class="mb-6">
      <h2 class="text-center text-h5 text-md-h4">
        <slot name="title">{{ title }}</slot>
      </h2>
      <p class="text-md-landing-h6 text-center mt-4 text-ui-kit-text-secondary">
        <slot name="subtitle">{{ subtitle }}</slot>
      </p>
    </div>
    <slot />
  </section>
</template>

<script lang="ts">
export default defineComponent({
  name: 'HomeSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
})
</script>
